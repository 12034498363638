/*sup_acc_user_details Start*/

.sup_acc_user_details {
    width: 100%;
    float: left;
    position: relative;
    top: 0px !important;
    font-family: 'Seravek-Medium';
    background: inherit;
    padding: 20px;
}

.sup_acc_user_details_header_top .border_radius_round {
    background: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}

.sup_acc_user_details_header_top .border_radius_round span {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
}

.sup_acc_user_details_header_top {
    display: flex;
    align-items: center;
    color: #494949;
}

.sup_acc_user_details_header_top h6 {
    margin-bottom: 0px;
    margin-left: 10px;
    float: left;
    width: 90%;
    font-size: 24px;
    color: #494949;
}

.sup_acc_user_details_header_top button {
    width: 140px;
    font-family: 'Seravek-Medium';
    font-size: 16px;
    padding: 0 10px;
    border-radius: 18px;
    border: 0.5px solid #ff7900;
    background: #ff7900;
    color: #fff;
    outline: 0;
    text-align: center;
    cursor: pointer;
    height: 30px;
}

.sup_acc_user_details_header {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    width: 100%;
    float: left;
    margin-bottom: 60px;
    padding: 20px 20px 75px 20px;
}

.border_botom {
    border-bottom: 1px solid #ccc;
    margin-top: 20px;
    margin-bottom: 20px;
}

.account_details_accordian {
    background: #f4f4f4;
    height: 60px;
    padding: 0px 20px;
    width: 100%;
    float: left;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
}

.account_details_accordian h5 {
    width: 98%;
    float: left;
    margin-bottom: 0px;
    font-size: 18px;
    color: #494949;
}

.account_details_accordian span {
    float: right;
}

.account_details_accordian span svg {
    width: 30px;
    height: 30px;
    float: right;
}

.account_users_current_plan {
    width: 100%;
    float: left;
    margin-bottom: 60px;
}

.account_users_current_plan_left h4 {
    font-size: 18px;
    color: #0a89e8;
    width: 70%;
    float: left;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.account_users_current_plan_right h4 {
    font-size: 18px;
    color: #0a89e8;
    width: 70%;
    float: left;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}

.account_users_current_plan button {
    float: right;
    width: 30%;
    font-family: 'Seravek-Medium';
    font-size: 16px;
    padding: 0 10px;
    border-radius: 18px;
    border: 0.5px solid #0a89e8;
    background: #0a89e8;
    color: #fff;
    outline: 0;
    text-align: center;
    cursor: pointer;
    height: 30px;
}

.account_users_current_plan_header {
    border: 1px solid #ccc;
    border-radius: 18px;
    width: 100%;
    float: left;
    margin-top: 30px;
}

.account_users_current_plan_header h6 {
    width: 100%;
    float: left;
    padding: 0px 20px;
    background: #f4f4f4;
    height: 60px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    margin-bottom: 0px;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

/* START TOOLTIP STYLES */

.account_users_current_plan [tooltip] {
    position: relative;
}

/* Applies to all tooltips */

.account_users_current_plan [tooltip]::before,
.account_users_current_plan [tooltip]::after {
    text-transform: none;
    font-size: 0.9em;
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
}

.account_users_current_plan [tooltip]::before {
    content: '';
    border: 5px solid transparent;
    z-index: 1001;
}

.account_users_current_plan [tooltip]::after {
    content: attr(tooltip);
    font-family: 'Seravek-Regular';
    text-align: center;
    width: 270px;
    line-height: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    background: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    color: #494949;
    z-index: 1000;
    white-space: pre-wrap;
}

/* Make the tooltips respond to hover */

.account_users_current_plan [tooltip]:hover::before,
.account_users_current_plan [tooltip]:hover::after {
    display: block;
}

/* don't show empty tooltips */

.account_users_current_plan [tooltip='']::before,
.account_users_current_plan [tooltip='']::after {
    display: none !important;
}

/* FLOW: UP */

.account_users_current_plan [tooltip]:not([flow])::before,
.account_users_current_plan [tooltip][flow^='up']::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #fff;
}

.account_users_current_plan [tooltip]:not([flow])::after,
.account_users_current_plan [tooltip][flow^='up']::after {
    bottom: calc(100% + 5px);
}

.account_users_current_plan [tooltip]:not([flow])::before,
.account_users_current_plan [tooltip]:not([flow])::after,
.account_users_current_plan [tooltip][flow^='up']::before,
.account_users_current_plan [tooltip][flow^='up']::after {
    left: 50%;
    transform: translate(-50%, -0.5em);
}

/* KEYFRAMES */

@keyframes tooltips-vert {
    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

@keyframes tooltips-horz {
    to {
        opacity: 1;
        transform: translate(0, -50%);
    }
}

/* FX All The Things */

.account_users_current_plan [tooltip]:not([flow]):hover::before,
.account_users_current_plan [tooltip]:not([flow]):hover::after,
.account_users_current_plan [tooltip][flow^='up']:hover::before,
.account_users_current_plan [tooltip][flow^='up']:hover::after,
.account_users_current_plan [tooltip][flow^='down']:hover::before,
.account_users_current_plan [tooltip][flow^='down']:hover::after {
    animation: tooltips-vert 300ms ease-out forwards;
}

.account_users_current_plan [tooltip][flow^='left']:hover::before,
.account_users_current_plan [tooltip][flow^='left']:hover::after,
.account_users_current_plan [tooltip][flow^='right']:hover::before,
.account_users_current_plan [tooltip][flow^='right']:hover::after {
    animation: tooltips-horz 300ms ease-out forwards;
}

.account_users_current_plan ul li span svg {
    width: 12px;
    height: 12px;
    color: #999;
    margin-bottom: 0;
    margin-left: 5px;
    cursor: pointer;
    /* margin-top: 6px; */
    position: relative;
    top: 2px;
}

.account_users_current_plan ul li b {
    font-family: 'Seravek-Medium';
}

.account_users_current_plan:before,
.account_users_current_plan span {
    font-size: 14px;
    font-family: 'Seravek-Regular';
}

.account_users_current_plan ul {
    padding: 30px;
    width: 100%;
    float: left;
    margin-bottom: 0px;
}

.account_users_current_plan ul li {
    font-size: 15px;
    text-align: left;
    margin-bottom: 10px;
    font-family: 'Seravek-Regular';
    color: #494949;
    font-weight: 500;
    list-style: none;
}

.account_users_current_plan ul li label {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid #2468f6;
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-right: 5px;
}

.account_users_current_plan_right_table {
    width: 100%;
    float: left;
}

.account_users_current_plan_right_table tr td span svg {
    width: 12px;
    height: 12px;
    color: #999;
    margin-bottom: 0;
    cursor: pointer;
    position: relative;
    top: 0px;
}

.account_users_current_plan_right {
    width: 100%;
    float: right;
}

.account_users_current_plan_right_table {
    border-radius: 18px;
    border: 1px solid #ccc;
    margin: 30px 0px;
}

.account_users_current_plan_right_table .table-active > th:first-child {
    background-color: #f4f4f4;
    border-top-left-radius: 18px;
}

.account_users_current_plan_right_table .table-active > th:last-child {
    background-color: #f4f4f4;
    border-top-right-radius: 18px;
}

.account_users_current_plan_right_table .table {
    margin-bottom: 0px;
}

.account_users_current_plan_right_table .table tr {
    border-bottom: 1px solid #ccc;
}

.account_users_current_plan_right_table .table tr:last-child {
    border-bottom: none;
}

.account_users_current_plan_right_table .table tr td {
    padding-bottom: 0px;
    height: 45px;
    text-align: center;
}

.account_users_current_plan_right_table .table tr th {
    border-bottom: 1px solid #ccc;
}

.account_users_current_plan_right_table .table tr td span {
    margin-left: 5px;
}

.account_users_current_plan_right_table .table tr td span svg {
    width: 14px;
    height: 14px;
    position: relative;
    top: 2px;
}

.account_users_current_plan_right_table .table-active > th {
    background-color: #f4f4f4;
    text-align: center;
}

.account_userent_plan_bottom .table tr td {
    text-align: left;
}

.account_userent_plan_bottom .table tr th {
    text-align: left;
}

.account_userent_plan_bottom .table tr td:nth-child(4) {
    position: absolute;
    margin-left: 5%;
}

.account_userent_plan_top {
    width: 100%;
    float: left;
}

.account_userent_plan_bottom {
    width: 100%;
    float: left;
}

.automation_invite_user {
    width: 100%;
    float: left;
}

.automation_profile_user {
    max-width: 100%;
    float: left;
    border: 1px solid #ccc;
    border-radius: 18px;
    padding: 20px;
    flex: 0 0 100%;
    margin: 0px auto;
    display: block;
    margin-top: 0px;
    position: relative;
}

.automation_profile_user_svg {
    width: 100%;
    float: right;
    cursor: pointer;
    text-align: right;
    position: absolute;
    right: 20px;
}

.automation_profile_user_svg svg {
    width: 30px;
    height: 30px;
    fill: #fff;
    text-align: right;
}

.automation_profile_user_imag {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    flex-direction: column;
}

/* .automation_profile_user_imag img,
.automation_profile_user_imag .default-dp {
    width: 148px;
    height: 148px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
} */

.automation_profile_user_imag h4 {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #000;
    font-family: 'Gordita-MEdium';
}

.automation_profile_user_imag h6 {
    text-align: center;
    font-size: 14px;
    color: #3366ff;
    font-family: 'Gordita-Regular';
    cursor: pointer;
}
.automation_profile_user_imag h6:hover{
    text-decoration: underline;
}
.automation_profile_user_imag p {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: 'Gordita-Regular';
    color: #000;
}

.automation_profile_user_svg1 {
    float: right;
    width: 60px;
    border-radius: 50%;
    background: #f0f0f0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 20px;
}

.automation_profile_user_svg1 .switch {
    position: relative;
    display: inline-block;
    width: 60px !important;
    height: 30px !important;
}

.automation_profile_user_svg1 .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.automation_profile_user_svg1 .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #dedede;
}

.automation_profile_user_svg1 .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 8px;
    bottom: 3px;
    background-color: #dedede;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.automation_profile_user_svg1 input:checked + .slider {
    border: 1px solid #2468F6;
    background: #2468F6;
}

.automation_profile_user_svg1 input:checked + .slider:before {
    background: #fff;
}

.automation_profile_user_svg1 input:focus + .slider {
    box-shadow: none;
}

.automation_profile_user_svg1 input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */

.automation_profile_user_svg1 .slider.round {
    border-radius: 34px;
}

.automation_profile_user_svg1 .slider.round:before {
    border-radius: 50%;
}

.automation_profile_user_bg {
    background: #f0f0f0;
}

.automation_profile_user_imag span {
    width: 148px;
    height: 148px;
    border: 1px solid #ccc;
    border-radius: 50%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin: 0 auto;
    margin-top: 35px;
}

.automation_profile_user_imag span svg {
    width: 80px;
    height: 80px;
    fill: #ccc;
}

.account_popup_user_input {
    margin-top: 0px;
}

.account_popup_user_input label {
    font-size: 12px;
    text-align: left;
    font-family: 'Gordita-Medium';
    margin-bottom: 2px;
    display: flex;
}

/* .account_popup_user .flow_popup1 .form-control {
    border: 0.5px solid #d1d1d1;
    font-size: 12px;
    padding: 5px 10px;
    height: 30px;
    font-family: 'Seravek-Medium';
    text-align: left;
    word-break: break-all;
    display: flex;
    margin-bottom: 0px;
} */

.automation_profile_user_svg .hover_radio_default_active {
    position: absolute;
    right: 0;
    z-index: 9;
    background: #383d56;
    color: #fff;
    width: auto;
    float: left;
    text-transform: uppercase;
    font-size: 10px;
    padding: 3px 10px;
    border-radius: 6px;
    margin-right: 0px;
    margin-top: -32px;
    font-family: 'Seravek-Regular';
    text-align: center;
    display: block;
}

.automation_profile_user_svg .hover_radio_default_deactive {
    position: absolute;
    right: 0;
    z-index: 9;
    background: #383d56;
    color: #fff;
    width: auto;
    float: left;
    text-transform: uppercase;
    font-size: 10px;
    padding: 3px 10px;
    border-radius: 6px;
    margin-right: 50px;
    margin-top: -32px;
    font-family: 'Gordita-Regular';
    text-align: center;
    display: block;
}

.excess_color {
    color: #ff7900;
}

.automation_invite_user h5 {
    width: auto;
    float: left;
    color: #000;
    font-family: 'Gordita-Medium';
    margin-right:12px;
}

.automation_invite_user .row {
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 20px;
    margin: 0px;
    padding: 0px;
}
.manage_users_scroll {
    width: 100%;
    float: left;
    height: calc(100vh - 35vh);
    overflow-y: scroll;
    margin-top: 40px
}
/*sup_acc_user_details End*/

.z-index-1-important {
    z-index: 1 !important;
}

.sup_acc_user_details .container{
    max-width: 100% ;
}
/* .automation_invite_user .row{
    margin-left: 15px;
} */
